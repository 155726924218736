import React, {
    createContext,
    FC,
    useContext,
    useEffect,
    useState,
} from "react";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";

// export interface useSnackbarProps {
//     status: 'error' | 'success' | 'warning' | 'info' | '';
//     message: string | null | undefined;
// }

export interface resMsgType {
    status: "error" | "success" | "warning" | "info" | "";
    message: string | null | undefined;
    timeout?: number;
}

const useSnackbar = () => {
    const [resMsg, setResponseMsg] = useState<resMsgType>({
        status: "",
        message: "",
        timeout: 3000,
    });

    const CustomSnackbar: FC = () => {
        if (resMsg?.message) {
            return (
                <div className="">
                    <Snackbar
                        autoHideDuration={3000}
                        open={!!resMsg?.message}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                    >
                        <Alert
                            severity={resMsg?.status || "info"}
                            className={`alert_${resMsg?.status}`}
                        >
                            {resMsg?.message}
                        </Alert>
                    </Snackbar>
                </div>
            );
        } else return null;
    };

    useEffect(() => {
        if (resMsg?.message) {
            setTimeout(
                () => {
                    setResponseMsg({ status: "", message: "" });
                },
                Number(resMsg?.timeout) || 3000,
            );
        }
    }, [resMsg]);

    const setResMsg = (resMsg: resMsgType) => {
        setResponseMsg(resMsg);
        return new Promise((resolve) => {
            setTimeout(resolve, resMsg?.timeout || 3000);
        });
    };

    return {
        resMsg,
        setResMsg,
        CustomSnackbar,
    };
};

export default useSnackbar;

export type SnackbarType = ReturnType<typeof useSnackbar>;
export const SnackbarContext = createContext<any>({});
export const useSnackbarContext: () => SnackbarType = () =>
    useContext(SnackbarContext);

export function SnackbarContextProvider(props) {
    const values = useSnackbar();
    return (
        <SnackbarContext.Provider value={values}>
            {props.children}
        </SnackbarContext.Provider>
    );
}
